// import * as esprimaLoad from 'esprima';
// const esprima = esprimaLoad.default?esprimaLoad.default:esprimaLoad;
import * as espree from "espree";
import Vue from 'vue';
// import { parse } from "espree";

window.espree = espree;

/**
 * UtilService
 */
const UtilService = {
  isValidJs : function(code, options) {
    // https://github.com/eslint/espree
    if(!options){
      options = {
        comment: false,
        ecmaVersion: 10,
        sourceType: "script",
        ecmaFeatures:{
          experimentalObjectRestSpread: true,
          spread: true,
        }
      };
    }
    // // https://esprima.org/demo/components.js
    // var isValid = true;
    // try {
    //   esprima.parse(testString);
    //   return true;
    // }
    // catch(e) {
    //   return e;
    // }
    try {
      espree.parse(code, options);
      return true;
    }catch(e){
      return e;
    }
  },
  isValidJs2 : function(code, options) {
    // https://github.com/eslint/espree
    if(!options){
      options = {
        comment: false,
        ecmaVersion: 10,
        sourceType: "commonjs",
        ecmaFeatures:{
          experimentalObjectRestSpread: true,
          spread: true,
        }
      };
    }
    // // https://esprima.org/demo/components.js
    // var isValid = true;
    // try {
    //   esprima.parse(testString);
    //   return true;
    // }
    // catch(e) {
    //   return e;
    // }
    try {
      espree.parse(code, options);
      return true;
    }catch(e){
      return e;
    }
  },
  prevNext: {
    v:{

    },
    d:{
      
    },
    init: function(key){
      if(!this.d){
        this.d = {};
      }
      if(!this.d[key]){
        this.d[key] = [[],[]];
        this.v[key] = {
          hasPrev:false,
          hasNext:false
        }
      }
    },
    store: function(key, value){
      this.init(key);
      if(!this.d[key][0].length || this.d[key][0][this.d[key][0].length-1] !== value){
        this.d[key][0].push(value);
        if(this.d[key][0].length > 5){
          this.d[key][0].shift();
        }
        this.d[key][1] = [];
        this.check(key);
      }
    },
    prev: function(key){
      // this.init(key);
      let popped = this.d[key][0].pop();
      this.d[key][1].unshift(popped);
      this.check(key);
      return this.d[key][0][this.d[key][0].length-1];
    },
    next: function(key){
      // this.init(key);
      let shifted = this.d[key][1].shift();
      this.d[key][0].push(shifted);
      this.check(key);
      return shifted;
    },
    check: function(key){
      this.v[key] = {
        hasPrev:this.d[key][0].length > 1,
        hasNext:this.d[key][1].length > 0
      };
      // console.log('l',this.d,this.v[key]);
    },
    isNumeric: function(str){
      if (typeof str != "string") return false // we only process strings!
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
    }
    // hasPrev: function(key){
    //   this.init(key);
    //   return this.d[key][0].length > 1;
    // },
    // hasNext: function(key){
    //   this.init(key);
    //   return this.d[key][1].length > 0;
    // }
  },
  scrollToTop: function(){
    window.scrollTo(0, 0);
  },
  downloadUrl: function(filePath) {
    var link=document.createElement('a');
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();
  }

};

export default UtilService;
